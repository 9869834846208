<template>
    <div class="vx-row"> 
            <div v-for="bannerspace in bannerspaces" 
            :key="bannerspace.uuid"
            class="vx-col w-full sm:w-1/2 md:w-1/2 mb-10">
                <EditBannerspaceBanner :bannerspace="bannerspace" :bannerUuid="bannerUuid" :banner="banner" @editDate="onEditDate" @isChecked="onSelectedBanner"/>
            </div>
    </div>
</template>

<script>
import EditBannerspaceBanner from '@/modules/Apps/Components/banners/form/EditBannerspaceBanner' 
export default {
    name: 'edit-resort-bannerspaces',
    components: {
        EditBannerspaceBanner
    },
    props: {
        bannerUuid: String,
        bannerspaces: Array,
        banner: Object
    },
    methods: {
        onEditDate(datatype, value, banerspaceuuid ) {
            this.$emit('editBannerInBannerSpace', datatype, value, banerspaceuuid)
        },
        onSelectedBanner(bannerSpaceUuid, checked) {
            this.$emit('deleteFromBannerSpace', bannerSpaceUuid, !checked)
        }
    }
}
</script>

<style>
    .bi-calendar,.bi-calendar-fill{
        color: #c4c4c4;
    }
</style>
