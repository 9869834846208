<template>
    <div>
        <vs-card actionable class="cardx rounded-lg">
            <div slot="media" class="card-image-container w-full relative rounded-lg">
                <img @click="handleEditBanner" :src="banner.image" :alt="banner.reference" class="absolute inset-0 rounded-t-lg">
            </div>
            <div class="px-3 pt-1">
                <div class="h-12 flex justify-between">
                    <div class="w-4/5">
                        <h3 class="text-sm font-bold">{{ banner.reference }} <span class="font-normal">({{ banner.size.name }})</span></h3>
                        <p class="text-xs ellipsis" v-if="banner.url">{{ banner.url }}</p>
                    </div>
                    <div class="w-1/5 text-right">
                        <p v-if="banner.language !== null" class="uppercase">{{ banner.language }}</p>
                        <vs-icon v-else icon="language" size="18px" color="#999999"></vs-icon>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="w-1/3 vx-col">
                        <p v-if="banner.summer" class="text-primary"><feather-icon icon="SunIcon" svgClasses="text-primary align-middle mr-1" class="w-5 align-middle"/> {{ $t("summer") | capitalize }} </p>
                        <p v-if="banner.winter" class="text-dark"><img src="@/assets/images/icons/icon-winter.svg" /> {{ $t("winter") | capitalize }} </p>
                    </div>
                    <div class="w-full flex flex-row justify-end items-center gap-2 p-2">
                        <button class="sk-btn-small sk-btn-danger rounded-lg btn-border" @click="handleRemoveBanner">
                            <feather-icon icon="TrashIcon" svgClasses="text-danger align-middle w-4" class="align-middle"/>
                        </button>
                        <button class="sk-btn-small sk-btn-primary rounded-lg btn-border" @click="handleEditBanner">
                            <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-4" class="align-middle"/>
                        </button>
                    </div>
                </div>
            </div>
        </vs-card>
    </div>
</template>

<script>

export default {
    name: 'banner-card',
    props: {
        type: String,
        banner: Object,
    },
    components: {},
    data:()=>({
        existsInSummer:false,
        existsInWinter:false,
    }),
    methods: {
        handleEditBanner() {
            this.$emit('edit', this.banner.uuid, this.type)
        },
        handleRemoveBanner() {
            this.$emit('remove', this.banner.uuid)
        }
    },
}
</script>

<style scoped>
.button-banner-remove {
    height: 44px !important;
}
.vx-card .vx-card__collapsible-content img {
    display: inline;
}

.card-image-container {
    padding-top: 40%;
}

.ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

</style>

<style>
    .vs-card--media {
        border-radius: 8px 8px 0 0 !important;
    }
</style>
