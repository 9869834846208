<template>
    <div>
        <CheckBoxForm @isChecked="onBannerIsChecked" :checked="checked" :disabled="banner.size.uuid !== bannerspace.size.uuid">
            {{ bannerspace.name + ' (' + bannerspace.size.name + ')' }}
        </CheckBoxForm>
        <div v-if="checked" class="ml-10">
            <div class="vx-row mt-5">
                <div class="vx-col w-20 font-bold pt-2">{{ $t("banner.edit.start") }}</div>
                <b-form-datepicker  class="w-48 mb-1 rounded-lg"
                                    today-button
                                    reset-button
                                    close-button
                                    v-model="start" 
                                    placeholder="" 
                                    :name="bannerspace.name" 
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                    :locale="applicationLanguage" />
            </div>
            <div class="vx-row mt-5">
                <div class="vx-col w-20 font-bold pt-2">{{ $t("banner.edit.end") }}</div>
                <b-form-datepicker  class="w-48 mb-1 rounded-lg"
                                    today-button
                                    reset-button
                                    close-button
                                    v-model="end" 
                                    placeholder="" 
                                    :name="bannerspace.name" 
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                    :locale="applicationLanguage"/>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from 'luxon';
import CheckBoxForm from '@/modules/Shared/Components/form/CheckBoxForm'
import {mapGetters} from 'vuex';
export default {
    name: 'edit-banner-spacebanner',
    components: {
        CheckBoxForm
    },
    data() {
        return {
            'checked': false,
            'start': '',
            'end': '',
        }
    },
    props: {
        bannerspace: Object,
        bannerUuid: String,
        banner: Object
    },
    computed: {
        ...mapGetters( ['applicationLanguage']),
    },
    created() {
        const bannerInBannerspace = this.getBannerInBannerspace();
        this.checked = this.isBannerInBannerspace(bannerInBannerspace)
        if(this.checked) {
            this.start = bannerInBannerspace.publish_start_date?DateTime.fromSeconds(bannerInBannerspace.publish_start_date).toJSDate():'';
            this.end = bannerInBannerspace.publish_end_date?DateTime.fromSeconds(bannerInBannerspace.publish_end_date).toJSDate():'';
        }   
    },
    methods: {
        isBannerInBannerspace(bannerInBannerspace) {
            return bannerInBannerspace !== undefined
        },
        getBannerInBannerspace() {
            return this.bannerspace.banners.find((banner) => banner.uuid === this.bannerUuid)   
        },
        onBannerIsChecked: function(checked) {
            this.checked =  checked;
            this.$emit('isChecked', this.bannerspace.uuid, checked)
        }
    },
    watch:{
        start: function (val) {
            let startDate = DateTime.fromISO(val).toUTC()
            this.$emit('editDate', 'start', startDate.toSeconds(), this.bannerspace.uuid)
        },
        end: function (val) {
            let endDate = DateTime.fromISO(val).toUTC()
            this.$emit('editDate', 'end', endDate.toSeconds(), this.bannerspace.uuid)
        }
    }
}
</script>