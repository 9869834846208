import axios from '@/axios.js'

export default {
    createBanner: async (appUuid, payload) => {
        try {
            const headers = {headers: {'Content-Type': 'multipart/form-data'} };
            const response = await axios.editorapi.post(`apps/v3/${appUuid}/banners`, payload, headers)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    updateBanner: async (appUuid,bannerUuid, payload) => {
        try {
            const headers = {headers: {'Content-Type': 'multipart/form-data'} };
            const response =  await axios.editorapi.post(`apps/v3/${appUuid}/banners/${bannerUuid}`,payload, headers)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    getBanners: async (appUuid, type = 'standard', page = 1) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/banners?page=${page}&type=${type}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getBanner: async (appUuid, bannerUuid) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/banners/${bannerUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getBannerSpaces: async (appUuid, page) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/bannerspaces?page=${page}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getBannerSpacebanners: async (appUuid, bannerSpaceUuid, page) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/bannerspaces/${bannerSpaceUuid}/banners?page=${page}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    updateBannerInBannerspace:async (appUuid, bannerSpaceUuid, bannerUuid, payload) => {
        try {
            const response = await axios.editorapi.patch(`apps/v3/${appUuid}/bannerspaces/${bannerSpaceUuid}/banners/${bannerUuid}`, null,{params: payload})
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    updateBannerspace:async (appUuid, bannerSpaceUuid, payload) => {
        try {
            const response = await axios.editorapi.patch(`apps/v3/${appUuid}/bannerspaces/${bannerSpaceUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    createBannerspace:async (bannerSpaceUuid, payload) => {
        try {
            const response = await axios.editorapi.post(`v4/apps/bannerspaces/${bannerSpaceUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteBanner: async (appUuid, bannerUuid) => {
        try {
            const response = await axios.editorapi.delete(`apps/v3/${appUuid}/banners/${bannerUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteBannerInBannerspace: async (appUuid, bannerSpaceUuid, bannerUuid) => {
        try {
            const response = await axios.editorapi.delete(`apps/v3/${appUuid}/bannerspaces/${bannerSpaceUuid}/banners/${bannerUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    sortBannersInBannerspace: async (appUuid, bannerSpaceUuid, banners) => {
        try {
            const payload = {sorted_banners:banners}
            const response = await axios.editorapi.patch(`apps/v3/${appUuid}/bannerspaces/${bannerSpaceUuid}/banners`, null,{params: payload})
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    bannerSpacesByResort: (bannerspaces) => {
      const getBannerspaceId = (bannerspace) => {
        if (bannerspace.resort) return bannerspace.resort.uuid
        if (bannerspace.reference === 'interstitial') return 'interstitial'
      }

      const getBannerSpaceName = (bannerspace) => {
        if (bannerspace.resort) return bannerspace.resort.name
        if (bannerspace.reference === 'interstitial') return 'Interstitial'
      }

      return bannerspaces.reduce((carry, bannerspace) => {
        const id = getBannerspaceId(bannerspace)
        const name = getBannerSpaceName(bannerspace)

        if(!carry.hasOwnProperty(id)) {
          carry[id] = {
            resort: { name, 'uuid': id },
            bannerspaces: []
          }
        }

        carry[id].bannerspaces = [...carry[id].bannerspaces, {
          'name': bannerspace.reference,
          'uuid': bannerspace.uuid,
          'size': {
            'uuid': bannerspace.size.uuid,
            'name': bannerspace.size.name
          },
          'banners': bannerspace.banners,
          'impression_frequency': bannerspace.impression_frequency
        }]

        return carry
      }, {})
    }
}
