var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "mt-8" }, [
      _c(
        "b",
        [
          _vm._t("default", function() {
            return [_vm._v(_vm._s(_vm.$t("Label")) + " ")]
          })
        ],
        2
      ),
      _vm.extra
        ? _c("span", { staticClass: "font-thin" }, [
            _vm._v(" " + _vm._s(_vm.extra) + " ")
          ])
        : _vm._e(),
      _c(
        "span",
        [
          _vm.info
            ? _c(
                "vx-tooltip",
                { attrs: { text: _vm.info, position: "top" } },
                [
                  _c("vs-icon", {
                    attrs: {
                      icon: "help",
                      round: "",
                      bg: "white",
                      color: "primary"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }