var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c("ModalCreateBannerSpace", {
        ref: "modalCreateBannerSpace",
        attrs: {
          resorts: _vm.resorts,
          type: _vm.type === "standard" ? "cover" : "interstitial"
        },
        on: {
          created: function($event) {
            return _vm.$emit("newBannerSpace")
          }
        }
      }),
      _c("vx-card", { staticClass: "bg-white mt-4 ml-8" }, [
        _c(
          "div",
          { staticClass: "flex flex-row justify-between mb-4 items-center" },
          [
            _c("h2", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.$t("banners.bannerspaces.title")))
            ]),
            _c(
              "button",
              {
                staticClass: "sk-btn sk-btn-primary px-6 py-3 rounded-lg",
                on: {
                  click: function($event) {
                    return _vm.$refs.modalCreateBannerSpace.open()
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("apps.bannerspaces.form.new.title")) + " "
                )
              ]
            )
          ]
        ),
        _c("p", { staticClass: "mb-16" }, [
          _vm._v(_vm._s(_vm.$t("bannerspaces.sort.description")))
        ]),
        _c(
          "div",
          { staticClass: "flex flex-col gap-10" },
          _vm._l(_vm.bannerSpacesByResort, function(resortBannerspaces) {
            return _c("ResortBannerSpace", {
              key: resortBannerspaces.uuid,
              attrs: { resortBannerspaces: resortBannerspaces, type: _vm.type },
              on: {
                edit: function($event) {
                  return _vm.$emit("bannerSpaceEdit")
                }
              }
            })
          }),
          1
        )
      ]),
      _c("vx-card", { staticClass: "bg-white mt-8 ml-8" }, [
        _c(
          "div",
          { staticClass: "w-full flex justify-between items-center mb-8" },
          [
            _c("h2", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.$t("banners.title")))
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row gap-2 items-center" },
              [
                _vm.bannerspaces
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg xs:w-full sm:w-auto",
                        attrs: {
                          to: {
                            name:
                              _vm.type === "standard"
                                ? "create-banner"
                                : "create-banner-interstitial",
                            params: {
                              bannerSpaces: this.bannerspaces,
                              type: _vm.type
                            }
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("banners.button.addbanner")) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c("feather-icon", {
                  staticClass: "text-primary cursor-pointer",
                  attrs: { icon: "HelpCircleIcon" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("showPopup")
                    }
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "vx-row" },
          _vm._l(_vm.banners, function(banner) {
            return _c("BannerCard", {
              key: banner.uuid,
              staticClass: "w-full vx-col sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3",
              attrs: { type: _vm.type, banner: banner },
              on: { remove: _vm.handleRemoveBanner, edit: _vm.handleEditBanner }
            })
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }