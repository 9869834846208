var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.active,
            title: _vm.title,
            classContent: "pb-10 pl-10 pr-10 overflow-auto",
            fullscreen: _vm.fullscreen
          },
          on: {
            "update:active": function($event) {
              _vm.active = $event
            }
          }
        },
        [
          _vm._t("body"),
          _c("div", { staticClass: "w-full" }, [
            _c(
              "div",
              {
                class:
                  "mt-5 flex " +
                  (_vm.actionButtonsPosition === "left"
                    ? "justify-start"
                    : "" + _vm.actionButtonsPosition === "center"
                    ? "justify-center"
                    : "" + _vm.actionButtonsPosition === "right"
                    ? "justify-end"
                    : "")
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "inline-block mr-2 rounded-lg sk-btn sk-btn-primary btn-border",
                    on: {
                      click: function($event) {
                        _vm.active = false
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(_vm.closeButtonKey)) + " ")]
                ),
                _vm.hasAcceptButton
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "inline-block rounded-lg sk-btn sk-btn-primary",
                        attrs: { disabled: _vm.acceptButtonDisabled },
                        on: { click: _vm.handleAccept }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(_vm.acceptButtonKey)) + " ")]
                    )
                  : _vm._e()
              ]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }