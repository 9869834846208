var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.resortBannerspaces.resort.name))
      ]),
      _vm._l(_vm.resortBannerspaces.bannerspaces, function(bannerspace) {
        return _c("BannerSpaceBanners", {
          key: bannerspace.uuid,
          attrs: { type: _vm.type, bannerSpace: bannerspace },
          on: {
            edit: function($event) {
              return _vm.$emit("edit")
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }