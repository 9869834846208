<template>
    <div class="w-full vx-row md:w-2/3 lg:w-1/2">
        <vx-card>
        <div class="mt-3 vx-row">
            <header class="w-full vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <div v-if="uuid">
                    <h2 >{{ $t("banner.form.update") }}</h2>
                    <p>{{ $t("banner.form.description") }}</p>
                </div>
                <div v-else>
                    <h2>{{ $t("banner.form.add") }}</h2>
                    <p>{{ $t("banner.form.description") }}</p>
                </div>
            </header>
        </div>

        <div :class="{'w-1/2': this.type === 'interstitial'}">
            <LabelForm :info="extraInfoImage">{{ $t("banner.form.image") }}</LabelForm>
            <pre>{{ this.type === 'standard' ? $t("banners.coverbanner.upload_help_text") : $t("banners.interstitalbanner.upload_help_text") }}</pre>
            <ImageForm
                        id="bannerImage"
                        :valid-sizes="validSizes"
                        validationWeight="500"
                        :validationMessage="validationMessageImage"
                        :src="image"
                        @updateImage="handlerUpdateBannerImage"
                        name="banner-image"/>
        </div>

        <LabelForm :info="extraInfoReference">{{ $t("banner.form.reference") }}</LabelForm>
        <vs-input class="w-full border-grey-light" v-model="reference"/>

        <LabelForm :info="extraInfoLanguage">{{ $t("banner.form.language") }}</LabelForm>
        <vs-select class="border-grey-light xs:w-full sm:w-1/2" v-model="language">
            <vs-select-item :key="item.id" :value="item.acronym" :text="item.name" v-for="item in this.languages"/>
        </vs-select>

        <LabelForm :info="extraInfoUrl">{{ $t("banner.form.url") }}</LabelForm>
        <vs-input class="w-full border-grey-light xs:w-full" v-model="url"/>

        <template v-if="uuid">
            <h3 class="mt-8 text-base text-bold">{{ $t("banner.form.bannerspaces") }}</h3>
            <div v-for="bannerSpaces in bannerSpacesByResort" :key="bannerSpaces.uuid">
                <h3 class="mt-6 mb-2 text-base">{{ bannerSpaces.resort.name }}</h3>
                <EditResortBannerspaces
                    :bannerspaces="bannerSpaces.bannerspaces"
                    :bannerUuid="uuid"
                    :banner="banner"
                    @editBannerInBannerSpace="onEditBannerInBannerSpace"
                    @deleteFromBannerSpace="handleDeleteBannerFromBannerspace" />
            </div>
        </template>

        <h3 class="mt-8 text-base text-bold">{{ $t("banner.form.others") }}</h3>
        <div class="w-full vx-row lg:w-full">
            <CheckBoxForm class="w-full mb-10 vx-col md:w-1/2" @isChecked="onOpenInAppIsChecked" :checked="show_in_app" :info="extraInfoOpenLink"> {{ $t("banner.form.openlink") }}</CheckBoxForm>
        </div>

        <b-alert :show="errorsOnSave.length > 0" variant="danger" class="rounded-lg">
            <ul class="list-none">
                <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
            </ul>
        </b-alert>

        <div class="buttons">
            <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveBanner()">{{ $t("banner.form.save") }}</vs-button>
            <vs-button color="#242426" type="border" class="w-48 rounded-lg" @click="cancelBanner()">{{ $t("banner.form.cancel") }}</vs-button>
        </div>
        </vx-card>
    </div>
</template>

<script>
import BannerService from '@/modules/Apps/Services/BannerService'
import localStorageHelper from '@/modules/Shared/Helpers/localStorageHelper'
import AppsService from '@/modules/Apps/Services/AppsService'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import ImageForm from '@/modules/Shared/Components/form/ImageForm'
import Datepicker from "vuejs-datepicker";
import EditResortBannerspaces from '@/modules/Apps/Components/banners/form/EditResortBannerspaces'
import CheckBoxForm from '@/modules/Shared/Components/form/CheckBoxForm'
import {mapGetters} from 'vuex';
import store from "@/modules/Shared/Store/store";

const imageSizes = {
    standard: {
      sizes: [
        { width: '1500', height: '600' },
        { width: '1500', height: '843' },
        { width: '1500', height: '1125' }
      ]
    },
    interstitial: {
      sizes: [
        { width: '640', height: '960' },
      ]
    }
}

export default {
    name: 'banner-form',
    components: {
        LabelForm,
        ImageForm,
        EditResortBannerspaces,
        CheckBoxForm,
        Datepicker
    },
    data() {
        return {
            validSizes: [],
            uuid: null,
            image: null,
            reference : '',
            language: null,
            url: '',
            is_trackeable: false,
            show_in_app: false,
            bannerSpacesToUpdate: {},
            bannerSpacesToDelete: [],
            bannerImageEdited: null,
            bannerSpacesByResort: {},
            languages: {},
            errorsOnSave:[],
            extraInfoImage: this.$t("banner.form.infoimage"),
            extraInfoReference: this.$t("banner.form.inforeference"),
            extraInfoLanguage: this.$t("banner.form.infolanguage"),
            extraInfoUrl: this.$t("banner.form.infourl"),
            extraInfoOpenLink: this.$t("banner.form.infoopenlink"),
            validationMessageImage: this.$t("banner.form.imageerror")
        }
    },
    async beforeRouteEnter (to, from, next) {
        const banner = ['edit-banner', 'edit-banner-interstitial'].includes(to.name)
            ? to.params.banner
            : null
        await store.dispatch('setRouteElement', banner)
        next()
    },
    props: {
        type: String,
        banner: Object,
        bannerSpaces: Array,
    },
    computed: {
        ...mapGetters( ['applicationUuid']),
    },
    async created() {
        this.fillFormFields();
        this.validSizes = imageSizes[this.type].sizes
    },
    destroyed() {
        localStorageHelper.remove('banner');
        localStorageHelper.remove('bannerspaces');
    },
    methods: {
        getBannerManagementRouteName() {
            return this.type === 'standard' ? 'banners' : 'banners-interstitials'
        },
        onEditBannerInBannerSpace(datatype, value, banerspaceuuid) {
            this.addBannerSpaceToBannerSpacesToUpdate(banerspaceuuid);
            this.bannerSpacesToUpdate[banerspaceuuid][datatype] = value;
        },
        onAutotrackIsChecked(val) {
            this.is_trackeable = val
        },
        onOpenInAppIsChecked(val) {
            this.show_in_app = val
        },
        handlerUpdateBannerImage(file) {
            this.bannerImageEdited = file;
        },
        handleDeleteBannerFromBannerspace(bannerSpaceUuid, deleting) {
            if(deleting) {
                delete this.bannerSpacesToUpdate[bannerSpaceUuid]
                this.bannerSpacesToDelete.push(bannerSpaceUuid);
            }else{
                this.addBannerSpaceToBannerSpacesToUpdate(bannerSpaceUuid);
                this.bannerSpacesToDelete = this.bannerSpacesToDelete.filter(function(bannerspace) { return bannerspace !== bannerSpaceUuid});
            }
        },
        addBannerSpaceToBannerSpacesToUpdate(banerspaceuuid) {
            if(!this.bannerSpacesToUpdate){
                this.bannerSpacesToUpdate = [];
            }
            if(!this.bannerSpacesToUpdate[banerspaceuuid]){
                this.bannerSpacesToUpdate[banerspaceuuid] = [];
            }
        },
        async fillFormFields() {
            const bannerEdited = localStorageHelper.set('banner',this.banner);
            if(bannerEdited) {
                this.uuid           = bannerEdited.uuid;
                this.image          = bannerEdited.image;
                this.reference      = bannerEdited.reference;
                this.language       = bannerEdited.language;
                this.url            = bannerEdited.url;
                this.is_trackeable  = bannerEdited.is_trackeable;
                this.show_in_app    = bannerEdited.show_in_app;
            }

            const bannerSpacesEdited = localStorageHelper.set('bannerspaces',this.bannerSpaces);
            this.bannerSpacesByResort = BannerService.bannerSpacesByResort(bannerSpacesEdited)

            this.languages =  await this.getLanguages();
        },
        async getLanguages() {
            let languages = await AppsService.getLanguages(this.applicationUuid);
            languages.unshift({id:999,name:this.$t('banner.choose.alllanguages'),'acronym':null});
            return languages;
        },
        saveBanner() {
            this.errorsOnSave = [];
            if(this.uuid) {
                this.editBanner();
            } else {
                this.createBanner();
            }
        },
        async createBanner() {
            if(!this.bannerImageEdited){
                this.errorsOnSave.push(this.$t("banner.form.imagenotempty"))
            }
            if(!this.reference){
                this.errorsOnSave.push(this.$t("banner.form.referencenotempty"))
            }
            if(this.errorsOnSave.length === 0) {
                let payload = new FormData();
                payload.append('image',this.bannerImageEdited)
                payload.append('reference',this.reference)
                payload.append('is_trackeable', this.is_trackeable ? 1 : 0)
                payload.append('show_in_app',this.show_in_app ? 1 : 0)
                payload.append('language',this.language?this.language:'all')
                payload.append('type',this.type)
                if(this.url) {
                    payload.append('url',this.url)
                }

                let response =  await BannerService.createBanner(this.applicationUuid, payload);
                if(response.code == 200) {
                    this.$vs.notify({text:this.$t("banner.created.ok"),color:'success',position:'top-right'})
                    setTimeout(() => {
                        this.$router.push({'name': this.getBannerManagementRouteName(),'params':{'uuid':this.applicationUuid,'typeEdited':this.type}});
                    }, 1000)
                } else {
                    this.$vs.notify({text:this.$t("banner.created.ko"),color:'danger',position:'top-right'})
                }
            }
        },
        cancelBanner() {
            this.$router.push({'name': this.getBannerManagementRouteName(),'params':{'uuid':this.applicationUuid,'typeEdited':this.type}});
        },
        async editBanner() {
            if(this.checkDataBannerSpace()){
                const promises = [
                    ...this.getDeleteBannerSpacesQueries(),
                    ...this.getUpdateBannerInBannerSpacesQueries(),
                    this.getEditBannerQuery()
                ];
                Promise.all(promises)
                .then(() => {
                    this.$vs.notify({text:this.$t("banner.updated.ok"), color:'success', time: 950,position:'top-right'})
                    setTimeout(() => {
                        this.$router.push({'name': this.getBannerManagementRouteName(), 'params':{'uuid':this.applicationUuid, 'typeEdited':this.type, position:'top-right'}});
                    }, 1000)
                })
                .catch(() => {
                    this.$vs.notify({text:this.$t("banner.updated.ko"),color:'danger'})
                })
            }else{
                this.errorsOnSave.push(this.$t("Start date can not be greather than end date"))
            }
        },
        checkDataBannerSpace(){
            let isCorrect = true
            let start = null
            let end = null
            for(let bannerspace in this.bannerSpacesToUpdate) {
                start = this.bannerSpacesToUpdate[bannerspace]['start'];
                end = this.bannerSpacesToUpdate[bannerspace]['end'];
                if(start && end && start >= end) {
                    isCorrect = false
                    break;
                }
            }
            return isCorrect
        },

        getEditBannerQuery() {
            let payload = new FormData();
            if(this.bannerImageEdited) {
                payload.append('image',this.bannerImageEdited)
            }
            if(this.reference) {
                payload.append('reference',this.reference)
            }
            if(this.url) {
                payload.append('url',this.url)
            }
            payload.append('language',this.language?this.language:'all')

            payload.append('is_trackeable', this.is_trackeable ? 1 : 0)
            payload.append('show_in_app',this.show_in_app ? 1 : 0)
            payload.append('type', this.type)

            return BannerService.updateBanner(this.applicationUuid, this.uuid, payload);
        },
        getDeleteBannerSpacesQueries() {
            return  this.bannerSpacesToDelete.map(function(bannerspace){
                        return BannerService.deleteBannerInBannerspace(this.applicationUuid, bannerspace, this.uuid)
                    },this)
        },
        getUpdateBannerInBannerSpacesQueries(){
            const UpdateBannerInBannerSpacesQueries = [];

            for(let bannerspace in this.bannerSpacesToUpdate) {
                let payload = {};
                if(this.bannerSpacesToUpdate[bannerspace]['start']) {
                    payload.start_date = this.bannerSpacesToUpdate[bannerspace]['start'];
                }
                if(this.bannerSpacesToUpdate[bannerspace]['end']) {
                    payload.end_date = this.bannerSpacesToUpdate[bannerspace]['end'];
                }
                UpdateBannerInBannerSpacesQueries.push(BannerService.updateBannerInBannerspace(this.applicationUuid, bannerspace, this.uuid, payload));
            }
            return UpdateBannerInBannerSpacesQueries;
        },
    }
}
</script>

<style>
  pre {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
</style>
