<template>
  <div>
    <vs-popup :active.sync="active" :title="title" classContent="pb-10 pl-10 pr-10 overflow-auto" :fullscreen="fullscreen">
        <slot name="body"></slot>
        <div class="w-full">
            <div
                :class="`mt-5 flex ${actionButtonsPosition === 'left' ? 'justify-start' : '' +
                    actionButtonsPosition === 'center' ? 'justify-center' : '' +
                    actionButtonsPosition === 'right' ? 'justify-end' : ''}`"
            >
                <button
                    class="inline-block mr-2 rounded-lg sk-btn sk-btn-primary btn-border"
                    @click="active = false"
                >
                    {{ $t(closeButtonKey) }}
                </button>
                <button v-if="hasAcceptButton"
                    :disabled="acceptButtonDisabled"
                    class="inline-block rounded-lg sk-btn sk-btn-primary"
                    @click="handleAccept"
                >
                    {{ $t(acceptButtonKey) }}
                </button>
            </div>
        </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  name: "SKModal",
  props: {
    title: {
      type: String,
      default: '',
    },
    initialActive: {
        type: Boolean,
        default: false
    },
    closeButtonKey: {
        type: String,
        default: 'apps.modals.cancel'
    },
    hasAcceptButton: {
        type: Boolean,
        default: false
    },
    acceptButtonKey: {
        type: String,
        default: 'Save'
    },
    acceptButtonDisabled: {
        type: Boolean,
        default: false
    },
    acceptCallback: {
        type: Function,
        default: () => false
    },
    actionButtonsPosition: {
        type: String,
        default: 'left'
    },
    fullscreen: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
        active: this.initialActive
    }
  },
  methods: {
    async handleAccept() {
        if (this.acceptCallback) {
            this.active = await this.acceptCallback()
        }
    },
    open() {
        this.active = true
    },
    close() {
        this.active = false
    }
  }
};
</script>
