var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CheckBoxForm",
        {
          attrs: {
            checked: _vm.checked,
            disabled: _vm.banner.size.uuid !== _vm.bannerspace.size.uuid
          },
          on: { isChecked: _vm.onBannerIsChecked }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.bannerspace.name + " (" + _vm.bannerspace.size.name + ")"
              ) +
              " "
          )
        ]
      ),
      _vm.checked
        ? _c("div", { staticClass: "ml-10" }, [
            _c(
              "div",
              { staticClass: "vx-row mt-5" },
              [
                _c("div", { staticClass: "vx-col w-20 font-bold pt-2" }, [
                  _vm._v(_vm._s(_vm.$t("banner.edit.start")))
                ]),
                _c("b-form-datepicker", {
                  staticClass: "w-48 mb-1 rounded-lg",
                  attrs: {
                    "today-button": "",
                    "reset-button": "",
                    "close-button": "",
                    placeholder: "",
                    name: _vm.bannerspace.name,
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    },
                    locale: _vm.applicationLanguage
                  },
                  model: {
                    value: _vm.start,
                    callback: function($$v) {
                      _vm.start = $$v
                    },
                    expression: "start"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-row mt-5" },
              [
                _c("div", { staticClass: "vx-col w-20 font-bold pt-2" }, [
                  _vm._v(_vm._s(_vm.$t("banner.edit.end")))
                ]),
                _c("b-form-datepicker", {
                  staticClass: "w-48 mb-1 rounded-lg",
                  attrs: {
                    "today-button": "",
                    "reset-button": "",
                    "close-button": "",
                    placeholder: "",
                    name: _vm.bannerspace.name,
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    },
                    locale: _vm.applicationLanguage
                  },
                  model: {
                    value: _vm.end,
                    callback: function($$v) {
                      _vm.end = $$v
                    },
                    expression: "end"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }