export async function unifyPaginatedResponse(methodWithPaginatedResponse, extraParam = null) {
  let countedResources = 0 
  let totalResources = 0
  let page = 1
  let resultsToReturn = []
  let resources = null
  do {
      resources = extraParam?
      await methodWithPaginatedResponse(extraParam, page):
      await methodWithPaginatedResponse(page);
    if(resources) {
      resultsToReturn = resultsToReturn.concat(resources.data)
      totalResources = resources.total
      countedResources += resources.data.length
      
      page++;  
    }
  }while(countedResources < totalResources && typeof resources === 'object')

  return resultsToReturn
}


