var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full vx-row md:w-2/3 lg:w-1/2" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "mt-3 vx-row" }, [
            _c(
              "header",
              {
                staticClass:
                  "w-full vx-col sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
              },
              [
                _vm.uuid
                  ? _c("div", [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("banner.form.update")))]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("banner.form.description")))
                      ])
                    ])
                  : _c("div", [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("banner.form.add")))]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("banner.form.description")))
                      ])
                    ])
              ]
            )
          ]),
          _c(
            "div",
            { class: { "w-1/2": this.type === "interstitial" } },
            [
              _c("LabelForm", { attrs: { info: _vm.extraInfoImage } }, [
                _vm._v(_vm._s(_vm.$t("banner.form.image")))
              ]),
              _c("pre", [
                _vm._v(
                  _vm._s(
                    this.type === "standard"
                      ? _vm.$t("banners.coverbanner.upload_help_text")
                      : _vm.$t("banners.interstitalbanner.upload_help_text")
                  )
                )
              ]),
              _c("ImageForm", {
                attrs: {
                  id: "bannerImage",
                  "valid-sizes": _vm.validSizes,
                  validationWeight: "500",
                  validationMessage: _vm.validationMessageImage,
                  src: _vm.image,
                  name: "banner-image"
                },
                on: { updateImage: _vm.handlerUpdateBannerImage }
              })
            ],
            1
          ),
          _c("LabelForm", { attrs: { info: _vm.extraInfoReference } }, [
            _vm._v(_vm._s(_vm.$t("banner.form.reference")))
          ]),
          _c("vs-input", {
            staticClass: "w-full border-grey-light",
            model: {
              value: _vm.reference,
              callback: function($$v) {
                _vm.reference = $$v
              },
              expression: "reference"
            }
          }),
          _c("LabelForm", { attrs: { info: _vm.extraInfoLanguage } }, [
            _vm._v(_vm._s(_vm.$t("banner.form.language")))
          ]),
          _c(
            "vs-select",
            {
              staticClass: "border-grey-light xs:w-full sm:w-1/2",
              model: {
                value: _vm.language,
                callback: function($$v) {
                  _vm.language = $$v
                },
                expression: "language"
              }
            },
            _vm._l(this.languages, function(item) {
              return _c("vs-select-item", {
                key: item.id,
                attrs: { value: item.acronym, text: item.name }
              })
            }),
            1
          ),
          _c("LabelForm", { attrs: { info: _vm.extraInfoUrl } }, [
            _vm._v(_vm._s(_vm.$t("banner.form.url")))
          ]),
          _c("vs-input", {
            staticClass: "w-full border-grey-light xs:w-full",
            model: {
              value: _vm.url,
              callback: function($$v) {
                _vm.url = $$v
              },
              expression: "url"
            }
          }),
          _vm.uuid
            ? [
                _c("h3", { staticClass: "mt-8 text-base text-bold" }, [
                  _vm._v(_vm._s(_vm.$t("banner.form.bannerspaces")))
                ]),
                _vm._l(_vm.bannerSpacesByResort, function(bannerSpaces) {
                  return _c(
                    "div",
                    { key: bannerSpaces.uuid },
                    [
                      _c("h3", { staticClass: "mt-6 mb-2 text-base" }, [
                        _vm._v(_vm._s(bannerSpaces.resort.name))
                      ]),
                      _c("EditResortBannerspaces", {
                        attrs: {
                          bannerspaces: bannerSpaces.bannerspaces,
                          bannerUuid: _vm.uuid,
                          banner: _vm.banner
                        },
                        on: {
                          editBannerInBannerSpace:
                            _vm.onEditBannerInBannerSpace,
                          deleteFromBannerSpace:
                            _vm.handleDeleteBannerFromBannerspace
                        }
                      })
                    ],
                    1
                  )
                })
              ]
            : _vm._e(),
          _c("h3", { staticClass: "mt-8 text-base text-bold" }, [
            _vm._v(_vm._s(_vm.$t("banner.form.others")))
          ]),
          _c(
            "div",
            { staticClass: "w-full vx-row lg:w-full" },
            [
              _c(
                "CheckBoxForm",
                {
                  staticClass: "w-full mb-10 vx-col md:w-1/2",
                  attrs: {
                    checked: _vm.show_in_app,
                    info: _vm.extraInfoOpenLink
                  },
                  on: { isChecked: _vm.onOpenInAppIsChecked }
                },
                [_vm._v(" " + _vm._s(_vm.$t("banner.form.openlink")))]
              )
            ],
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "rounded-lg",
              attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
            },
            [
              _c(
                "ul",
                { staticClass: "list-none" },
                _vm._l(_vm.errorsOnSave, function(errorString, index) {
                  return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
                }),
                0
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled rounded-lg w-48 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveBanner()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("banner.form.save")))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "w-48 rounded-lg",
                  attrs: { color: "#242426", type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.cancelBanner()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("banner.form.cancel")))]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }