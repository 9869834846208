var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row" },
    _vm._l(_vm.bannerspaces, function(bannerspace) {
      return _c(
        "div",
        {
          key: bannerspace.uuid,
          staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 mb-10"
        },
        [
          _c("EditBannerspaceBanner", {
            attrs: {
              bannerspace: bannerspace,
              bannerUuid: _vm.bannerUuid,
              banner: _vm.banner
            },
            on: { editDate: _vm.onEditDate, isChecked: _vm.onSelectedBanner }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }