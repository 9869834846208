var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      actionButtonsPosition: "right",
      hasAcceptButton: true,
      acceptCallback: _vm.create,
      title: _vm.$t("apps.bannerspaces.form.new.title")
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm.bannerSpace.type === "cover"
              ? _c(
                  "div",
                  [
                    _c("LabelForm", [
                      _vm._v(
                        _vm._s(_vm.$t("apps.bannerspaces.form.resortLabel"))
                      )
                    ]),
                    _c(
                      "vs-select",
                      {
                        staticClass: "w-50",
                        model: {
                          value: _vm.bannerSpace.resort_uuid,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerSpace, "resort_uuid", $$v)
                          },
                          expression: "bannerSpace.resort_uuid"
                        }
                      },
                      _vm._l(_vm.resorts, function(resort) {
                        return _c("vs-select-item", {
                          key: resort.uuid,
                          attrs: { value: resort.uuid, text: resort.name }
                        })
                      }),
                      1
                    ),
                    _c("LabelForm", [
                      _vm._v(_vm._s(_vm.$t("banner.form.reference")))
                    ]),
                    _c("vs-input", {
                      staticClass: "input-modal w-full border-grey-light",
                      model: {
                        value: _vm.bannerSpace.reference,
                        callback: function($$v) {
                          _vm.$set(_vm.bannerSpace, "reference", $$v)
                        },
                        expression: "bannerSpace.reference"
                      }
                    }),
                    _c("LabelForm", [
                      _vm._v(
                        _vm._s(_vm.$t("apps.bannerspaces.form.timeofyearLabel"))
                      )
                    ]),
                    _c(
                      "vs-select",
                      {
                        staticClass: "w-50",
                        model: {
                          value: _vm.bannerSpace.time_of_year,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerSpace, "time_of_year", $$v)
                          },
                          expression: "bannerSpace.time_of_year"
                        }
                      },
                      _vm._l(["winter", "summer"], function(timeofyear) {
                        return _c("vs-select-item", {
                          key: timeofyear,
                          attrs: { value: timeofyear, text: timeofyear }
                        })
                      }),
                      1
                    ),
                    _c("LabelForm", [
                      _vm._v(_vm._s(_vm.$t("apps.bannerspaces.form.sizeLabel")))
                    ]),
                    _c(
                      "vs-select",
                      {
                        staticClass: "w-50",
                        model: {
                          value: _vm.bannerSpace.size_uuid,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerSpace, "size_uuid", $$v)
                          },
                          expression: "bannerSpace.size_uuid"
                        }
                      },
                      _vm._l(_vm.sizes, function(size) {
                        return _c("vs-select-item", {
                          key: size.uuid,
                          attrs: { value: size.uuid, text: size.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("LabelForm", [
                      _vm._v(
                        _vm._s(_vm.$t("apps.bannerspaces.form.frequencyLabel"))
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "input-modal w-full border-grey-light",
                      attrs: { type: "number", min: "1" },
                      model: {
                        value: _vm.bannerSpace.frequency,
                        callback: function($$v) {
                          _vm.$set(_vm.bannerSpace, "frequency", $$v)
                        },
                        expression: "bannerSpace.frequency"
                      }
                    })
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }