<template>
    <div class="vx-row">
        <ModalCreateBannerSpace
            ref="modalCreateBannerSpace"
            :resorts="resorts"
            :type="type === 'standard' ? 'cover' : 'interstitial'"
            @created="$emit('newBannerSpace')"
        />
        <vx-card class="bg-white mt-4 ml-8">
            <div class="flex flex-row justify-between mb-4 items-center">
                <h2 class="mb-0">{{ $t('banners.bannerspaces.title') }}</h2>
                <button @click="$refs.modalCreateBannerSpace.open()" class="sk-btn sk-btn-primary px-6 py-3 rounded-lg">
                    {{ $t('apps.bannerspaces.form.new.title') }}
                </button>
            </div>

            <p class="mb-16">{{ $t("bannerspaces.sort.description") }}</p>

            <div class="flex flex-col gap-10">
                <ResortBannerSpace
                    v-for="(resortBannerspaces) in bannerSpacesByResort"
                    :key="resortBannerspaces.uuid"
                    :resortBannerspaces="resortBannerspaces"
                    :type="type"
                    @edit="$emit('bannerSpaceEdit')"
                />
            </div>
        </vx-card>

        <vx-card class="bg-white mt-8 ml-8">
            <div class="w-full flex justify-between items-center mb-8">
                <h2 class="mb-0">{{ $t('banners.title') }}</h2>
                <div class="flex flex-row gap-2 items-center">
                    <vs-button v-if="bannerspaces" class="rounded-lg xs:w-full sm:w-auto" :to="{
                  name: type === 'standard' ? 'create-banner' : 'create-banner-interstitial',
                  params: {
                    bannerSpaces: this.bannerspaces,
                    type
                  }
                }" >
                        {{ $t("banners.button.addbanner") }}
                    </vs-button>
                    <feather-icon icon="HelpCircleIcon" class="text-primary cursor-pointer" v-on:click.stop="$emit('showPopup')"/>
                </div>
            </div>
            <div class="vx-row">
                <BannerCard
                    v-for="banner in banners" :key="banner.uuid"
                    :type="type"
                    class="w-full vx-col sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3"
                    :banner="banner"
                    @remove="handleRemoveBanner"
                    @edit="handleEditBanner"
                />
            </div>
        </vx-card>

    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import BannerCard from '@/modules/Apps/Components/banners/BannerCard'
import ResortBannerSpace from '@/modules/Apps/Components/banners/ResortBannerSpace'
import {mapGetters} from 'vuex';
import BannerService from "@/modules/Apps/Services/BannerService";
import ModalCreateBannerSpace from "@/modules/Apps/Components/banners/ModalCreateBannerSpace.vue";
import AppsService from "@/modules/Apps/Services/AppsService";

export default {
    name: 'banners',
    components: {
        ModalCreateBannerSpace,
        BannerCard,
        ResortBannerSpace,
        LabelForm
    },
    data() {
        return {
            bannerSpacesByResort: {},
            bannerOptions: [],
            resorts: []
        }
    },
    props: {
        manageOption: String,
        type: String,
        banners: Array,
        bannerspaces: Array,
    },
    computed: {
        ...mapGetters( ['applicationUuid']),
    },
    async created() {
        this.resorts = await AppsService.getResorts(this.$route.params.uuid)
        this.bannerSpacesByResort = BannerService.bannerSpacesByResort(this.bannerspaces)
        this.bannerOptions =  [
          { label: this.$t("banners.option.spaces"), value: 'spaces' },
          { label: this.$t("banners.option.banners"), value: 'banners' }
        ]
    },
    watch: {
        async bannerspaces(){
          this.bannerSpacesByResort = BannerService.bannerSpacesByResort(this.bannerspaces)
        }
    },
    methods: {
        handleRemoveBanner(bannerUuid) {
            this.$emit('removeBanner', bannerUuid)
        },

        handleEditBanner(bannerUuid, type) {
            const banner = this.banners.find((banner) => banner.uuid === bannerUuid)
            const routeName = type === 'standard' ? 'edit-banner' : 'edit-banner-interstitial'

            this.$router.push({name: routeName, params:{'bannerUuid':bannerUuid,'banner':banner,'bannerSpaces':this.bannerspaces, type}});
        }
    }
}
</script>
