<template>
  <div>
      <h3 class="mb-4">{{ resortBannerspaces.resort.name }}</h3>
      <BannerSpaceBanners
          v-for="(bannerspace) in resortBannerspaces.bannerspaces"
          :type="type"
          :key="bannerspace.uuid"
          :bannerSpace="bannerspace"
          @edit="$emit('edit')"
      />
  </div>
</template>

<script>
import BannerSpaceBanners from '@/modules/Apps/Components/banners/BannerSpaceBanners'
export default {
    name: 'resort-bannerspace',
    components: {
        BannerSpaceBanners
    },
    props: {
        type : String,
        resortBannerspaces : Object
    }
}
</script>
