<template>
    <div class="mt-2 mb-10">
        <ModalEditBannerSpace
            ref="modalEditBannerSpace"
            :banner-space="bannerSpace"
            @edit="$emit('edit')"
        />
        <div>
            <div class="flex flex-row gap-4 items-center mb-2">
                <h5 class="mb-0">{{ bannerSpace.name + ' (' + bannerSpace.size.name + ')' }}</h5>
                <button class="rounded-lg sk-btn-small sk-btn-primary btn-border" @click="$refs.modalEditBannerSpace.open()">
                    <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-4" class="align-middle"/>
                </button>
            </div>

            <Datatable
                :config="datatableConfig"
                :elements="banners"
                :stripe="true"
                :draggable="true"
                :sst="false"
                @sort="handleSort"
                @action="handleAction"
            />
        </div>
    </div>
</template>

<script>
import {DateTime} from 'luxon';
import BannerService from '@/modules/Apps/Services/BannerService'
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import {mapGetters} from 'vuex';
import ModalEditBannerSpace from "@/modules/Apps/Components/banners/ModalEditBannerSpace.vue";
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'bannerspace-banners',
    mixins: [loader],
    components: {
        ModalEditBannerSpace,
        Datatable,
    },
    data() {
        return {
            banners: [],
            selectedBannerSpace: {
                uuid: null,
                impression_frequency: null
            },
            datatableConfig: [
                {'field':'reference', head: this.$t("banner.table.name"), 'searchable':false, 'sortable':true},
                {'field':'image','type': 'image',  head: this.$t("banner.table.preview"), 'searchable': false, 'sorteable': false },
                {'field':'publish_start_date', head: this.$t("banner.table.startdate"), 'searchable':false, 'sortable':true},
                {'field':'publish_end_date', head: this.$t("banner.table.enddate"), 'searchable':false, 'sortable':true},
                {'field':'language', head: this.$t("banner.table.language"), 'searchable':false, 'sortable':false},
                {'field':'actions', 'type': 'actions', head: this.$t("banner.table.actions"), 'searchable':false, 'sortable':false}
            ],
        }
    },
    props: {
        type : String,
        bannerSpace: Object,
    },
    computed: {
        ...mapGetters( ['applicationUuid','applicationLanguage']),
    },
    created() {
        this.getMappedBanners();
    },
    methods: {
        getMappedBanners() {
            this.banners = this.bannerSpace.banners
                .map(banner=> ({
                    'uuid':banner.uuid,
                    'image':banner.image,
                    'reference':banner.reference,
                    'publish_start_date':this.getFormatedDate(banner.publish_start_date),
                    'publish_end_date':this.getFormatedDate(banner.publish_end_date),
                    'language':banner.language ?banner.language.toUpperCase() : this.$t("banner.choose.alllanguages"),
                    'ordering':banner.ordering,
                    'actions': ['delete']
                }));
        },
        getFormatedDate(date) {
            return date?DateTime.fromSeconds(date).setLocale(this.applicationLanguage).toFormat('dd-LL-yyyy'):'';
        },
        async handleSort(newOrder) {
            await this.loadAndNotify(async () => {
                BannerService.sortBannersInBannerspace(this.applicationUuid, this.bannerSpace.uuid, newOrder.join(','))
            }, true)
        },
        handleAction(type, uuid) {
            switch(type) {
                case'delete':
                    this.removeBannerspaceBanner(this.bannerSpace.uuid, uuid)
                break;
            }
        },
        async removeBannerspaceBanner(bannerSpaceUuid, bannerUuid) {
            await this.loadAndNotify(async () => {
                await BannerService.deleteBannerInBannerspace(this.applicationUuid, bannerSpaceUuid, bannerUuid);
            })
        }
    },
    watch: {
        bannerSpace: function() {
            this.getMappedBanners();
        }
    }
}
</script>
