<template>
    <div class="w-full vx-row">
      <Banners
        :type="type"
        :banners="banners"
        :bannerspaces="bannerSpace"
        @removeBannerspaceBanner="handleRemoveBannerspaceBanner"
        @removeBanner="removeBanner"
        @showPopup="popup = true"
        @newBannerSpace="reload"
        @bannerSpaceEdit="reload"
      />
      <vs-popup class="text-center" title="" :active.sync="popup">
          <img v-if="type === 'standard'" src="@/assets/images/pages/banners/standard-banner.png"/>
          <img v-if="type === 'interstitial'" src="@/assets/images/pages/banners/interstitial-banner.png"/>
      </vs-popup>
    </div>
</template>

<script>
import BannerService from '@/modules/Apps/Services/BannerService'
import Banners from '@/modules/Apps/Pages/banners/Banners'
import { unifyPaginatedResponse } from "@/modules/Shared/Helpers/apiResponseHelper";
import {mapGetters} from 'vuex';
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'banners-manager',
    components: {
        Banners,
    },
    mixins: [loader],
    props: ['type'],

    data: () => ({
        popup: false,
        banners: [],
        bannerSpace: [],
    }),

    computed: {
        ...mapGetters( ['applicationUuid']),
    },
    async created() {
        await this.reload()
    },

    methods:{
        async reload() {
            await this.load(async () => {
                await this.processBanners()
            })
        },
        async updatedBannerSpaces(){
            const bannerSpaces = await this.getAllBannerspaceBanners();
            this.bannerSpace = this.type === 'standard'
              ? bannerSpaces.filter(bannerspace => bannerspace.reference.indexOf('interstitial') < 0)
              : bannerSpaces.filter(bannerspace => bannerspace.reference.indexOf('interstitial') >= 0)
        },
        async processBanners() {
            await this.updatedBannerSpaces()
            const banners = await unifyPaginatedResponse(this.getBanners, this.type)
            this.banners = this.addTimeOfYearToBanners(banners, this.banners);
        },

        async getBanners(type, page = 1) {
            return await BannerService.getBanners(this.applicationUuid, type, page)
        },

        async getBannerspaces(page = 1) {
            return await BannerService.getBannerSpaces(this.applicationUuid, page)
        },

        async getBannerspaceBanners(bannerSpaceUuid, page = 1) {
            return await BannerService.getBannerSpacebanners(this.applicationUuid, bannerSpaceUuid, page)
        },

        async getAllBannerspaceBanners() {
            const bannerSpaces = await unifyPaginatedResponse(this.getBannerspaces);

            return await Promise.all(bannerSpaces.map(async (bannerspace) => {
                bannerspace.banners = await unifyPaginatedResponse(this.getBannerspaceBanners, bannerspace.uuid);
                return bannerspace;
            }))
        },

        addTimeOfYearToBanners(banners, bannerSpaces){
            const bannersWithTimeofyear = [];
            banners.forEach(banner => {
                const currentBanner = { ...banner }
                currentBanner.summer =  this.bannerInBannerSpace(currentBanner, bannerSpaces, 'summer');
                currentBanner.winter =  this.bannerInBannerSpace(currentBanner, bannerSpaces, 'winter');

                bannersWithTimeofyear.push(currentBanner)
            })
            return bannersWithTimeofyear;
        },
        bannerInBannerSpace(currentBanner, bannerSpaces, timeOfYear) {
          if(bannerSpaces.length === 0) return false
          if(this.countResorts(bannerSpaces) !== 1) return false

          const banner_timeofyear = bannerSpaces.find(function(bannerspace) { return bannerspace.time_of_year === timeOfYear;})
          if(banner_timeofyear){
              return banner_timeofyear.banners.some((banner) => banner.uuid === currentBanner.uuid)
          }

          return false;
        },
        countResorts(bannerSpaces) {
            return [...new Set(bannerSpaces.filter(bannerspace => bannerspace.resort).map(bannerspace => bannerspace.resort.uuid))].length;
        },

        async handleRemoveBannerspaceBanner(bannerSpaceUuid, bannerUuid) {
            await this.loadAndNotify(async () => {
                await BannerService.deleteBannerInBannerspace(this.applicationUuid, bannerSpaceUuid, bannerUuid);
                await this.updatedBannerSpaces();
            })
        },
        async removeBanner(bannerUuid) {
            await this.loadAndNotify(async () => {
              await BannerService.deleteBanner(this.applicationUuid, bannerUuid);
            })
        },
    }
}
</script>
