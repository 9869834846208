var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full vx-row" },
    [
      _c("Banners", {
        attrs: {
          type: _vm.type,
          banners: _vm.banners,
          bannerspaces: _vm.bannerSpace
        },
        on: {
          removeBannerspaceBanner: _vm.handleRemoveBannerspaceBanner,
          removeBanner: _vm.removeBanner,
          showPopup: function($event) {
            _vm.popup = true
          },
          newBannerSpace: _vm.reload,
          bannerSpaceEdit: _vm.reload
        }
      }),
      _c(
        "vs-popup",
        {
          staticClass: "text-center",
          attrs: { title: "", active: _vm.popup },
          on: {
            "update:active": function($event) {
              _vm.popup = $event
            }
          }
        },
        [
          _vm.type === "standard"
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/pages/banners/standard-banner.png")
                }
              })
            : _vm._e(),
          _vm.type === "interstitial"
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/pages/banners/interstitial-banner.png")
                }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }