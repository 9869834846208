<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :hasAcceptButton="true"
        :acceptCallback="edit"
        :title="$t('apps.bannerspaces.form.update.title')"
    >

        <template v-slot:body v-if="bannerSpace">

            <div class="w-50">
                <LabelForm>{{  $t("banner.form.reference") }}</LabelForm>
                <vs-input name="reference" class="input-modal w-full border-grey-light" v-model="bannerSpace.name" />
            </div>

            <div v-if="isInsterstitial">
                <div class="w-50">
                    <LabelForm>{{  $t("apps.bannerspaces.form.frequencyLabel") }}</LabelForm>
                    <vs-input name="frequency" type="number" min="1" class="input-modal w-full border-grey-light" v-model="bannerSpace.impression_frequency" />
                </div>
            </div>

            <div v-else>
                <LabelForm>{{  $t("apps.bannerspaces.form.sizeLabel") }}</LabelForm>
                <vs-select class="w-50" v-model="bannerSpace.size.uuid">
                    <vs-select-item name="size" :key="size.uuid" :value="size.uuid" :text="size.name" v-for="size in sizes"/>
                </vs-select>
            </div>

        </template>
    </SKModal>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import SKModal from "@/modules/Shared/Components/modal/SKModal";
import loader from "@/modules/Shared/Mixins/loader";
import BannerService from "@/modules/Apps/Services/BannerService";

export default {
    name: 'ModalEditBannerSpace',
    mixins: [loader],
    props: {
        bannerSpace: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            sizes: [
                { 'uuid': '1be230ca-f6ad-454e-b177-b19defc9c125', 'name': '4:3' },
                { 'uuid': '4a659972-bf89-47ac-90de-993727eb2b43', 'name': '16:9' },
                { 'uuid': 'ec5b81cc-6519-4ed9-bbc3-637d9fe8e6e6', 'name': '5:2' }
            ]
        }
    },
    components: {
        LabelForm,
        SKModal,
    },
    methods: {
        open() {
            this.$refs['modal'].open()
        },
        async edit() {
            await this.loadAndNotify(async () => {
                const body = {
                    reference: this.bannerSpace.name
                }

                if (this.isInsterstitial) {
                    body.impression_frequency = this.bannerSpace.impression_frequency
                } else {
                    body.size_uuid = this.bannerSpace.size.uuid
                }

                await BannerService.updateBannerspace(this.$route.params.uuid, this.bannerSpace.uuid, body);
                this.$emit('edit')
            })
        }
    },
    computed: {
        isInsterstitial() {
            return this.bannerSpace.name === 'interstitial'
        }
    }
}
</script>
