var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      actionButtonsPosition: "right",
      hasAcceptButton: true,
      acceptCallback: _vm.edit,
      title: _vm.$t("apps.bannerspaces.form.update.title")
    },
    scopedSlots: _vm._u(
      [
        _vm.bannerSpace
          ? {
              key: "body",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-50" },
                    [
                      _c("LabelForm", [
                        _vm._v(_vm._s(_vm.$t("banner.form.reference")))
                      ]),
                      _c("vs-input", {
                        staticClass: "input-modal w-full border-grey-light",
                        attrs: { name: "reference" },
                        model: {
                          value: _vm.bannerSpace.name,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerSpace, "name", $$v)
                          },
                          expression: "bannerSpace.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isInsterstitial
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "w-50" },
                          [
                            _c("LabelForm", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "apps.bannerspaces.form.frequencyLabel"
                                  )
                                )
                              )
                            ]),
                            _c("vs-input", {
                              staticClass:
                                "input-modal w-full border-grey-light",
                              attrs: {
                                name: "frequency",
                                type: "number",
                                min: "1"
                              },
                              model: {
                                value: _vm.bannerSpace.impression_frequency,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.bannerSpace,
                                    "impression_frequency",
                                    $$v
                                  )
                                },
                                expression: "bannerSpace.impression_frequency"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _c(
                        "div",
                        [
                          _c("LabelForm", [
                            _vm._v(
                              _vm._s(_vm.$t("apps.bannerspaces.form.sizeLabel"))
                            )
                          ]),
                          _c(
                            "vs-select",
                            {
                              staticClass: "w-50",
                              model: {
                                value: _vm.bannerSpace.size.uuid,
                                callback: function($$v) {
                                  _vm.$set(_vm.bannerSpace.size, "uuid", $$v)
                                },
                                expression: "bannerSpace.size.uuid"
                              }
                            },
                            _vm._l(_vm.sizes, function(size) {
                              return _c("vs-select-item", {
                                key: size.uuid,
                                attrs: {
                                  name: "size",
                                  value: size.uuid,
                                  text: size.name
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }