var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2 mb-10" },
    [
      _c("ModalEditBannerSpace", {
        ref: "modalEditBannerSpace",
        attrs: { "banner-space": _vm.bannerSpace },
        on: {
          edit: function($event) {
            return _vm.$emit("edit")
          }
        }
      }),
      _c(
        "div",
        [
          _c("div", { staticClass: "flex flex-row gap-4 items-center mb-2" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _vm._v(
                _vm._s(
                  _vm.bannerSpace.name + " (" + _vm.bannerSpace.size.name + ")"
                )
              )
            ]),
            _c(
              "button",
              {
                staticClass:
                  "rounded-lg sk-btn-small sk-btn-primary btn-border",
                on: {
                  click: function($event) {
                    return _vm.$refs.modalEditBannerSpace.open()
                  }
                }
              },
              [
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: {
                    icon: "Edit2Icon",
                    svgClasses: "text-primary align-middle w-4"
                  }
                })
              ],
              1
            )
          ]),
          _c("Datatable", {
            attrs: {
              config: _vm.datatableConfig,
              elements: _vm.banners,
              stripe: true,
              draggable: true,
              sst: false
            },
            on: { sort: _vm.handleSort, action: _vm.handleAction }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }