import { render, staticRenderFns } from "./BannerSpaceBanners.vue?vue&type=template&id=04af9ff2&"
import script from "./BannerSpaceBanners.vue?vue&type=script&lang=js&"
export * from "./BannerSpaceBanners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04af9ff2')) {
      api.createRecord('04af9ff2', component.options)
    } else {
      api.reload('04af9ff2', component.options)
    }
    module.hot.accept("./BannerSpaceBanners.vue?vue&type=template&id=04af9ff2&", function () {
      api.rerender('04af9ff2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Apps/Components/banners/BannerSpaceBanners.vue"
export default component.exports