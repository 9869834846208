var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-card",
        { staticClass: "cardx rounded-lg", attrs: { actionable: "" } },
        [
          _c(
            "div",
            {
              staticClass: "card-image-container w-full relative rounded-lg",
              attrs: { slot: "media" },
              slot: "media"
            },
            [
              _c("img", {
                staticClass: "absolute inset-0 rounded-t-lg",
                attrs: { src: _vm.banner.image, alt: _vm.banner.reference },
                on: { click: _vm.handleEditBanner }
              })
            ]
          ),
          _c("div", { staticClass: "px-3 pt-1" }, [
            _c("div", { staticClass: "h-12 flex justify-between" }, [
              _c("div", { staticClass: "w-4/5" }, [
                _c("h3", { staticClass: "text-sm font-bold" }, [
                  _vm._v(_vm._s(_vm.banner.reference) + " "),
                  _c("span", { staticClass: "font-normal" }, [
                    _vm._v("(" + _vm._s(_vm.banner.size.name) + ")")
                  ])
                ]),
                _vm.banner.url
                  ? _c("p", { staticClass: "text-xs ellipsis" }, [
                      _vm._v(_vm._s(_vm.banner.url))
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "w-1/5 text-right" },
                [
                  _vm.banner.language !== null
                    ? _c("p", { staticClass: "uppercase" }, [
                        _vm._v(_vm._s(_vm.banner.language))
                      ])
                    : _c("vs-icon", {
                        attrs: {
                          icon: "language",
                          size: "18px",
                          color: "#999999"
                        }
                      })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "w-1/3 vx-col" }, [
                _vm.banner.summer
                  ? _c(
                      "p",
                      { staticClass: "text-primary" },
                      [
                        _c("feather-icon", {
                          staticClass: "w-5 align-middle",
                          attrs: {
                            icon: "SunIcon",
                            svgClasses: "text-primary align-middle mr-1"
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("capitalize")(_vm.$t("summer"))) +
                            " "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.banner.winter
                  ? _c("p", { staticClass: "text-dark" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/icon-winter.svg")
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("capitalize")(_vm.$t("winter"))) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "w-full flex flex-row justify-end items-center gap-2 p-2"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "sk-btn-small sk-btn-danger rounded-lg btn-border",
                      on: { click: _vm.handleRemoveBanner }
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "align-middle",
                        attrs: {
                          icon: "TrashIcon",
                          svgClasses: "text-danger align-middle w-4"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "sk-btn-small sk-btn-primary rounded-lg btn-border",
                      on: { click: _vm.handleEditBanner }
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "align-middle",
                        attrs: {
                          icon: "Edit2Icon",
                          svgClasses: "text-primary align-middle w-4"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }